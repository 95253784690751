.feedback_button {
  width: 70%;
  margin-left: 20px;
}

.text_feedback {
  text-align: center;
}

.feedback_comp {
  align-items: center;
  justify-content: center;
}

.form_feed {
  justify-content: center;
  align-items: center;
}

.input_feedback {
  width: 100%;
  margin: 20px;
}

.premium_button {
  background-color: #ff4500 !important;
  color: white !important;
  margin-left: 8px !important;
  font-size: 8.5px !important;
  padding: 0px 5px !important;
  border-radius: 5px !important;
  opacity: 0.8 !important;
  box-shadow: none !important;
}
