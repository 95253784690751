.resetPass {
  margin: -59px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .resetPass {
    margin: -29px;
  }
}
@media (min-width: 1024px) and (max-width: 1366px) {
  .resetPass {
    margin: -38px;
  }
}
@media (min-width: 1280px) and (max-width: 1300px) {
  .resetPass {
    margin: -49px;
  }
}
