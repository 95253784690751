/* Scrollbar track */
/* ::-webkit-scrollbar {
  width: 8px;
  border-radius: 8px;
  background-color: #d3d3d3;
} */

/* Scrollbar thumb */
/* ::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 8px;
  height: 40px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
} */

/* Change thumb color on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background-color: #3107cb;
} */

/* Scrollbar track */
::-webkit-scrollbar {
  width: 8px;
  background-color: transparent; /* Set background color to transparent for vertical track */
}

/* Vertical scrollbar track */
::-webkit-scrollbar-vertical {
  width: 8px;
  background-color: #d3d3d3; /* Set background color for vertical track */
  border-radius: 8px;
}

/* Scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #f5dbdb;
  border-radius: 8px;
  height: 40px;
  width: 6px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

/* Change thumb color on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #3107cb;
}
