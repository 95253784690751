.react-multi-email-custom {
  margin: 0;
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  padding: 0.4em 0.5em;
  padding-bottom: 2em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  -webkit-transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  font-size: 15px;
  font-family: var(--font-family-adv) !important;
}

.react-multi-email-custom.focused {
  border-color: #3107cb;
  background: #fff;
}

.react-multi-email-custom > input {
  width: auto !important;
  outline: none !important;
  border: 0 none !important;
  display: inline-block !important;
  line-height: 1;
  vertical-align: baseline !important;
  padding: 0.4em 0.1em !important;
}

.react-multi-email-custom > input:not([value=""]):not(:active):invalid {
  border: 2px solid red;
}
.react-multi-email-custom > span[data-placeholder] {
  display: none;
  position: absolute;
  left: 0.5em;
  top: 0.4em;
  padding: 0.4em;
  line-height: 1.21428571em;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .inviteText {
    left: 40% !important;
  }
}
@media (min-width: 1024px) {
  .inviteText {
    left: 35% !important;
  }
}
