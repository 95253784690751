.switch {
  position: relative;
  display: inline-block;
  width: 65px;
  height: 34px;
  top: 2px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slideToggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d3d3d3;
  transition: 0.4s;
}

.slideToggle::before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

.slideToggle.roundedSwitch {
  border-radius: 1.25em;
}
.slideToggle.roundedSwitch::before {
  border-radius: 1.25em;
}

input:checked + .slideToggle {
  background-color: #2196f3;
}
input:checked + .slideToggle::before {
  transform: translateX(26px);
}

.Toastify__toast-container {
  width: 360px !important;
}

.Toastify__toast-icon {
  margin-top: -5rem !important;
}
