.map {
  width: 250px;
  height: 110px;
  border-radius: 20px;
  margin-top: -50px;
}

@media (max-width: 850px) {
  .mapVisibility {
    display: none;
  }
}
@media (min-width: 851px) {
  .mapVisibility {
    margin-left: 50px;
  }
}
