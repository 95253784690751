.custom-no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* CAROUSEL CSS */

.carousel {
  position: relative;
  width: 32rem;
  height: 23rem;
  perspective: 500px;
  transform-style: preserve-3d;
}

.carouselImage {
  display: "flex";
  align-items: "center";
  justify-content: "center";
  background-size: "cover";
  /* height: 320px !important; */
  width: 700px !important;
}

@media (min-width: 768px) and (max-width: 820px) {
  .centerOnIpad {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .slideShowCenter {
    padding-top: 15rem !important;
  }
  .carousel {
    position: relative;
    width: 18rem;
    height: 23rem;
    perspective: 500px;
    transform-style: preserve-3d;
  }
}
@media (min-width: 1024px) and (max-width: 1024px) {
  .centerOnIpad {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .slideShowCenter {
    padding-top: 22rem !important;
  }
  .carousel {
    position: relative;
    width: 22rem;
    height: 23rem;
    perspective: 500px;
    transform-style: preserve-3d;
  }
}


.carousel-card-container {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotateY(calc(var(--offset) * 50deg))
    scaleY(calc(1 + var(--abs-offset) * -0.3))
    translateZ(calc(var(--abs-offset) * -30rem))
    translateX(calc(var(--direction) * -5rem));
  /* translateY(calc(var(--abs-offset) * 15rem)); */
  filter: blur(calc(var(--abs-offset) * 2rem));
  /* transition: all 0.5s ease-out; */
}

/* .card {
  width: 100%;
  height: 100%;
  padding: 2rem;
  background-color: hsl(280deg, 40%, calc(100% - var(--abs-offset) * 50%));
  border-radius: 1rem;
  color: #9ca3af;
  text-align: justify;
  /* transition: all 0.5s ease-out; */

/* h2 {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    margin: 0 0 0.7em;
    color: #1f2937;
  }

  p,
  h2 {
    /* transition: all 0.5s ease-out; */
/* opacity: var(--active);
  }
}   */

/* .nav {
  color: white;
  font-size: 5rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  z-index: 2;
  cursor: pointer;
  user-select: none;
  background: unset;
  border: unset;

  &.left {
    transform: translateX(-100%) translatey(250%);
  }

  &.right {
    right: 0;
    transform: translateX(100%) translatey(250%);
  }
} */
