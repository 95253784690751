.message_circle {
  background: rgba(254, 105, 60, 0.75);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  top: 80%;
}

@media (min-width: 990px) and (max-width: 1215px) {
  .message_circle {
    top: 90%;
  }
}
@media (min-width: 1217px) and (max-width: 1286px) {
  .message_circle {
    top: 85%;
  }
}
