.smallGreenCircle {
  background: rgba(71, 190, 155, 0.28);
  border-radius: 100%;
  width: 25px;
  height: 25px;
  left: -2.15%;
  right: 92.78%;
  top: 17.54%;
  /* bottom: 89.36%; */
  /* box-shadow: 10px 14px 40px rgba(0, 0, 0, 0.25); */
}

.smallYellowCircle {
  background: linear-gradient(
      0deg,
      rgba(255, 216, 110, 0.75),
      rgba(255, 216, 110, 0.75)
    ),
    rgba(255, 216, 110, 0.75);
  border-radius: 100%;
  width: 25px;
  height: 25px;
  left: 80.15%;
  right: 92.78%;
  top: 3.5%;
  /* bottom: 89.36%; */
  /* box-shadow: 10px 14px 40px rgba(0, 0, 0, 0.25); */
}
.smallYellowCircle2 {
  background: #ffda77;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  left: 955px;
  /* right: 92.78%; */
  top: 407px;
  /* bottom: 89.36%; */
  /* box-shadow: 10px 14px 40px rgba(0, 0, 0, 0.25); */
}
.smallWhitishBlueCircle {
  background: #c5e7df;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  left: 270px;
  top: 590px;
  /* bottom: 89.36%; */
  /* box-shadow: 10px 14px 40px rgba(0, 0, 0, 0.25); */
}



.circle {
  position: relative;
  background: #35a080;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.checkMark {
  position: absolute;
  transform: rotate(35deg) translate(-50%, -50%);
  left: 30%;
  top: 45%;
  height: 10px;
  width: 7px;
  border-bottom: 3px solid white;
  border-right: 3px solid white;
}

.placeTextAbove {
  position: relative;
  text-align: center;
  color: white;
}
