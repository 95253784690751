.activeListColor {
  color: #3107cb;
  font-weight: 500;
}

.MuiTypography-body1 {
  font-weight: 300 !important;
}

.smallGreenCircleTrial {
  background: rgba(71, 190, 155, 0.28);
  border-radius: 100%;
  width: 25px;
  height: 25px;
  left: 2.15%;
  right: 92.78%;
  top: 25.54%;
  /* bottom: 89.36%; */
  /* box-shadow: 10px 14px 40px rgba(0, 0, 0, 0.25); */
}

.smallYellowCircleTrial {
  background: linear-gradient(
      0deg,
      rgba(255, 216, 110, 0.75),
      rgba(255, 216, 110, 0.75)
    ),
    rgba(255, 216, 110, 0.75);
  border-radius: 100%;
  width: 25px;
  height: 25px;
  left: 80.15%;
  right: 92.78%;
  top: 7%;
  /* bottom: 89.36%; */
  /* box-shadow: 10px 14px 40px rgba(0, 0, 0, 0.25); */
}
.smallYellowCircle2Trial {
  background: #ffda77;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  left: 755px;
  /* right: 92.78%; */
  top: 407px;
  /* bottom: 89.36%; */
  /* box-shadow: 10px 14px 40px rgba(0, 0, 0, 0.25); */
}

/* LESS THAN equal 1068 */
@media (min-width: 1024px) {
  .ipAnalytics {
    width: 42%;
    margin-top: 4.5em;
    margin-left: 30%;
  }
}
/* Max width: 768, 768 and less than 768 */
/* Min width 768, 768 and more */
@media (min-width: 768px) and (max-width: 1023px) {
  .ipAnalytics {
    width: 80%;
    margin-top: 4.5em;
    margin-left: 10%;
  }
}

/* MOBILE VIEW */
@media (max-width: 767px) {
  .ipAnalytics {
    width: 70%;
    margin-top: 4.5em;
    margin-left: 10%;
  }
}

@media (min-width: 768px) {
  /* Adjust the styles for smaller screens */
  .resMemeLogo {
    width: 80px;
    height: 25px;
    margin-top: -65px;
    margin-left: 10px;
  }

  .resText {
    font-size: 18px;
    margin-left: 10px;
  }
}

.checkmark {
  display: inline-block;
  width: 21px;
  height: 21px;
  margin-left: 5px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
  border-radius: 50%;
  background-color: rgba(1, 4, 136, 0.9);
}

.checkmark_stem {
  position: absolute;
  width: 3px;
  height: 9px;
  background-color: white;
  left: 11px;
  top: 6px;
}
.neww {
  background-color: "rgba(1, 4, 136, 0.9) !important";
}

.number {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 6px;
  background: #3107cb !important;
  border: 3px solid #3107cb !important;
  color: #fff;
  text-align: center;
}

#searchId::placeholder {
  color: black;
}

@media screen and (max-width: 760px) {
  .btns {
    display: flex;
    flex-direction: column;
    justify-content: space-around !important;
    margin: 5px;
  }

  .bt {
    margin-top: 10px;
  }
}

.checkmark_kick {
  position: absolute;
  width: 3px;
  height: 3px;
  background-color: white;
  left: 8px;
  top: 12px;
}

.drop {
  box-shadow: none;
  text-decoration: none;
  outline-offset: none !important;
  outline: none !important;
  /* border-color: rgba(0, 0, 0, 0.03); */
}
.drop:hover {
  box-shadow: none;
  text-decoration: none;
  outline-offset: none;
  border-color: rgba(0, 0, 0, 0.03);
  /* background-color: rgba(0, 0, 0, 0.03); */
}
