.faq-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.faq-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #3107CB;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.faq-button:hover {
  transform: scale(1.05);
  background-color: #3da688;
}

.faq-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(86, 84, 84, 0.221);
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.faq-modal-content {
  background-color: white;
  border-radius: 20px;
  width: 400px;
  height: 80vh;
  margin: 18px;
  box-shadow: 0 10px 30px rgba(131, 128, 128, 0.116);
  display: flex;
  flex-direction: column;
  animation: slideIn 0.3s ease;
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.faq-modal-header {
  background-color: #2e07bf;
  color: white;
  height: 20%;
  padding: 20px;
  display: flex;
  justify-content: end;
  align-items: start;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: relative;
}

.faq-modal-header h2 {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
}

.faq-close-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.faq-close-button:hover {
  transform: scale(1.1);
}

.faq-modal-body {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
}

.faq-accordion-item {
  border-bottom: 1px solid #e0e0e0;
}

.faq-accordion-header {
  width: 100%;
  text-align: left;
  padding: 15px;
  background-color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: #333;
  transition: background-color 0.3s ease, color 0.3s ease;
  justify-content: space-between;
}

.faq-accordion-arrow {
  width: 20px;
  height: 20px;
  fill: #333;
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}
.faq-accordion-header.active .faq-accordion-arrow {
  transform: rotate(180deg);
}

.faq-header-text {
  position: absolute;
  left: 6%;
  top: 50%;
  display: flex;
  flex-direction: column;
}

.faq-modal-header h2 {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
}

.faq-header-text p {
  margin: 5px 0 0;
  font-size: 16px;
  font-weight: 400;
}
.faq-accordion-header:hover {
  background-color: #f5f5f5;
}

.faq-accordion-header.active {
  background-color: #e7f1ff;
  color: #2e07bf;
}

.faq-icon {
  margin-right: 10px;
  transition: transform 0.3s ease;
}

.faq-accordion-header.active .faq-icon {
  transform: rotate(90deg);
}

.faq-accordion-content {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.5s ease, opacity 0.5s ease, padding 0.5s ease;
}

.faq-accordion-content.active {
  max-height: 1000px;
  opacity: 1;
  padding: 15px;
}

.faq-accordion-content p {
  margin: 0;
  font-size: 14px;
  line-height: 1.6;
  color: #666;
}

.faq-modal-footer {
  color: white;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.faq-modal-footer h6 {
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  margin-right: 10px;
}

.faq-footer-logo {
  width: 100px;
  height: auto;
}

@media (max-width: 480px) {
  .faq-modal-content {
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 0;
  }

  .faq-modal-header,
  .faq-modal-footer {
    border-radius: 0;
  }
}
