.banner {
  border-radius: 0px 0px 10px 10px;
  background: #25bf6d;
  box-shadow: 0px 4px 15px 3px #47be9b;
  width: 564px;
  height: 43px;
  flex-shrink: 0;
  position: absolute;
  top: 0;
}
.confetti {
  font-size: 20px;
  position: absolute;
  top: 22px;
  left: -7px;
}
