/* .split {
    height: 100%;
    position:fixed;
    top: 0;
    overflow-x: hidden;
    padding-top: 20px;
  } */

/* Control the left side */
/* .left {
    float: left;
    left: 0;
    width: 35%;
    background-color: #fff;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  @media screen and (max-width: 750px) {
    .left {
      width: 100%;
    }
  }

  .top {
    left: 0;
    height: 100%;
    background-color: #3107CB;
  }


  /* Control the right side */
/* .right {
    float: right;
    width: 65%;
    right: 0;
    background-color: #F5F6FA;
    border-top-right-radius: 0.7rem;
    border-bottom-right-radius: 0.7rem;
  }
  @media screen and (max-width: 750px) {
    .right {
      width: 100%;
    }
  }

  .bottom {
    bottom: 0%;
    left: 0;
    top:  50%;
    background-color:white;
  }  */

/* If you want the content centered horizontally and vertically */
* {
  box-sizing: border-box;
}

.inputStyle {
  width: 50% !important;
  height: 45px;
  border-radius: 7px;
  border: 0px;
  margin-left: 8px;
  margin-right: 8px;
  background: #d3d3d3;
  font-size: 20px;
  color: black;
}

@media screen and (min-width: 1024px) {
  .random {
    margin-left: 120px;
    margin-right: 120px;
  }
}

.centered {
  padding: 40px;
  position: absolute;
  top: 49.62vh;
  width: 60vh;
  height: 78vh;
  left: 68%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: #fff;
  border-top-right-radius: 0.7em;
  border-bottom-right-radius: 0.7em;
}

@media screen and (max-width: 750px) {
  .centered {
    width: 70%;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .centered {
    margin-top: 10%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .centered {
    margin-top: 15%;
  }
}

/* Style the image inside the centered container, if needed */
.centered_img {
  bottom: 0%;
  border-radius: 50%;
}

.h2heading {
  font-weight: 600;
  color: #323250;
  font-size: 150%;
  font-family: var(--font-family-adv) !important;
  letter-spacing: -0.15px;
}

@media (min-width: 765px) {
  .h2heading {
    margin-top: 6%;
  }
}

.h3heading {
  font-weight: 600;
  color: #323250;
  font-size: 150%;
  font-family: var(--font-family-adv) !important;
  letter-spacing: -0.15px;
  /* font-family: Poppins; */
}

@media (min-width: 765px) {
  .h3heading {
    margin-top: 40px;
  }
}

@media (max-width: 764px) {
  .h3heading {
    margin-top: 50px;
  }
}

.hr {
  border: 1px solid white;
  width: 15%;
  position: absolute;
}

.hr1 {
  border: 0.5px solid #c6c7cb;
  width: 70%;
  position: absolute;
}

.hr2 {
  border: 0.5px solid #c6c7cb;
  width: 100%;
  position: absolute;
}

@media (max-width: 1280px) {
  .hr1 {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.hr2 {
  border: 0.5px solid #c6c7cb;
  width: 100%;
  /* position: absolute; */
}

@media (max-width: 1280px) {
  .hr2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .resetPx {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}

.signIn {
  border-radius: 6px;
  background: #3107cb;
  /* padding-left: 15px; */
  /* padding-right: 15px; */
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
  padding: 0.9rem 0.75rem;
  display: inline-block;
  font-size: 100%;
  font-weight: bold;
  color: white;
  text-decoration: none;
  border: none;
  align-items: center;
  justify-content: center;
}

.resend {
  border-radius: 6px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: inline-block;
  font-size: 100%;
  font-weight: bold;
  text-decoration: none;
  border: none;
  align-items: center;
  justify-content: center;
}

.signIn:hover {
  color: #fff;
  background-color: #411ad0;
  border-color: #411ad0;
}

.resend:hover {
  color: #fff;
  background-color: #411ad0;
  border-color: #411ad0;
}

.createAccount {
  border-radius: 10px;
  padding: 10px;
  display: inline-block;
  font-size: 90%;
  font-weight: bold;
  background-color: #3107cb;
  color: #fff;
  text-decoration: none;
  width: 100%;
  text-align: center;
}

.createAccount:hover {
  background-color: #411ad0;
  border-color: #411ad0;
}

@media (max-width: 1290px) and (min-width: 768px) {
  .createAccount {
    border-radius: 10px;
    padding: 10px;
    display: inline-block;
    font-size: 90%;
    font-weight: bold;
    background-color: #3107cb;
    color: #fff;
    text-decoration: none;
    width: 100%;
  }
}

/* @media (min-width: 820px) and (max-width: 1180px) {
	.createAccount {
		border-radius: 10px;
		padding: 10px;
		display: inline-block;
		font-size: 90%;
		font-weight: bold;
		background-color: #3107cb;
		color: #fff;
		text-decoration: none;
		width: 100%;
	}
} */

.forgettext {
  color: #585858;
  font-weight: bold;
  /* margin-top: 0.5em; */
  margin-bottom: 0.8em;
  /* margin-bottom: 20px; */
}

.forgettext:hover {
  color: #3107cb;
}

.needaccounttext {
  font-weight: bold;
  color: #585858;
  margin-top: 0.2em;
  cursor: pointer;
}

.needaccounttext:hover {
  color: #3107cb;
}

.inline-block-child {
  display: inline-block;
}

.rowkey {
  margin-top: 2%;
  margin-bottom: 2%;
  align-content: space-between;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

select {
  display: block;
  width: 100%;
  padding: 0.75rem;
  padding-left: 0;
  font-size: 1rem;
  line-height: 2;
  color: #585858;
  background-color: #f5f6fa;
  background-clip: padding-box;
  border: 1px solid gray;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.defaultoption {
  color: gray;
  background-color: gray;
}

/* --------------------------------------------------------------------------------------------------------------------- */

.rightside {
  height: 80vh;
  width: 105%;
}

.leftside {
  width: 100%;
  height: 80vh;
  overflow: hidden;
  position: relative;
}

.leftside {
  background: white;
  border-top-left-radius: 0.7rem;
  border-bottom-left-radius: 0.7rem;
}

.hi {
  position: relative;
  margin-left: -10px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .leftside {
    height: 50vh;
  }
}

@media (min-width: 768px) and (max-width: 1280px) {

  /* .signIn {
		border-radius: 6px;
		background: #3107cb;
		padding-left: 5px;
		padding-right: 5px;
		padding-top: 5px;
		padding-bottom: 5px;
		display: inline-block;
		font-size: 100%;
		font-weight: bold;
		color: white;
		text-decoration: none;
		border: none;
		align-items: center;
		justify-content: center;
		width: 60%;
	} */
  .rowkey {
    margin-top: 2%;
    margin-bottom: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.rightside {
  background: #fff;
  border-top-right-radius: 0.7rem;
  border-bottom-right-radius: 0.7rem;
}

@media (min-width: 480px) and (max-width: 767px) {
  .rightside {
    height: 100vh;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .rightside {
    height: 120vh;
  }
}

@media (max-width: 320px) {
  .rightside {
    height: 120vh;
  }
}

.topside,
.bottomside {
  height: 80vh;
  width: 100%;
}

.topside {
  background: #130061;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.containerLogo {
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 5%;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.bannerimg {
  width: 360px;
  height: 390px;
  /* margin-top: -7%; */
  /* background-color: #fff; */
  /* z-index: 2; */
  position: absolute;
  /* border: 10px solid #eae6fa; */
  /* border-radius: 15%; */
}

@media screen and (max-width: 1200px) {
  .bannerimg {
    margin-top: -20%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .bannerimg {
    width: 80%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .bannerimg {
    margin-top: -10%;
  }
}

.banner1img {
  height: 15%;
  margin-top: 40%;
  position: absolute;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .banner1img {
    margin-top: 70%;
    margin-left: 5%;
    width: 90%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .banner1img {
    height: 12%;
    display: none;
  }
}

.h2title {
  margin-top: 20%;
  color: #fff;
  font-size: 30px;
  font-family: var(--font-family-adv) !important;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .h2title {
    font-size: 140%;
  }
}

.greenCircle {
  background: #25c16e94;
  position: absolute;
  z-index: 1;
  border-radius: 100%;
  width: 342px;
  height: 342px;
  left: 78.92%;
  right: -8.61%;
  top: -15.53%;
  bottom: 72.36%;
  box-shadow: -26px 14px 138px rgba(0, 0, 0, 0.25);
  overflow-y: hidden;
  -webkit-filter: blur(80px);
  filter: blur(80px);
}

.smallGreenCircle {
  background: #7eca6e;
  border-radius: 100%;
  width: 15vh;
  height: 15vh;
  left: -2.15%;
  right: 92.78%;
  top: -2.54%;
  bottom: 89.36%;
  box-shadow: 10px 14px 40px rgba(0, 0, 0, 0.25);
}

.smallNewGreenCircle {
  background: #7eca6e;
  border-radius: 100%;
  width: 15vh;
  height: 15vh;
  left: -2.15%;
  right: 92.78%;
  top: -4.54%;
  bottom: 91%;
  box-shadow: 10px 14px 40px rgba(0, 0, 0, 0.25);
}

.smallNewGreenCircleBottom {
  background: #7eca6e;
  border-radius: 100%;
  width: 15vh;
  height: 15vh;
  right: -3%;
  bottom: -3%;
  box-shadow: 20px 20px 40px rgba(0, 0, 0, 0.25);
  /* fill: rgba(37, 193, 110, 0.58); */
  filter: drop-shadow(-87px 9px 138px rgba(0, 0, 0, 0.25));
}

.smallBlueCircle {
  background: #6258ff;
  border-radius: 100%;
  width: 15vh;
  height: 15vh;
  left: -2.15%;
  right: 92.78%;
  top: 89.54%;
  /* bottom: 89.36%; */
  box-shadow: 10px 14px 40px rgba(0, 0, 0, 0.25);
}

.loginGreenCircle {
  border-radius: 100%;
  width: 50vh;
  height: 50vh;
  left: 63.68%;
  right: 5.62%;
  top: 51.37%;
  bottom: 5.47%;
  background: rgba(82, 235, 141, 0.716);
  box-shadow: -87px 9px 138px rgba(0, 0, 0, 0.25);
  -webkit-filter: blur(80px);
  filter: blur(80px);
}

.errorBlueCircle {
  border-radius: 100%;
  width: 30vh;
  height: 30vh;
  left: 88.68%;
  right: 5.62%;
  top: -10.37%;
  /* bottom: 5.47%; */
  background: #1d0779;
  box-shadow: -87px 9px 138px rgba(0, 0, 0, 0.25);
}

.loginGreenCircleHollow {
  border-radius: 100%;
  /* z-index: 1; */
  width: 60vh;
  height: 60vh;
  left: 6.68%;
  right: 5.62%;
  top: 5.37%;
  border: 60px solid #3107cbb3;
  filter: drop-shadow(0px -75px 138px rgba(0, 0, 0, 0.25));
  transform: rotate(-220deg);
  overflow: hidden;
  -webkit-filter: blur(80px);
  filter: blur(80px);
}

.responsive-div {
  height: 100%;
  padding: 0 20px; /* Default padding for larger screens (lg and above) */
  width: 50%; /* Default for larger screens (lg and above) */
  
}

@media (max-width: 991.98px) { /* Bootstrap breakpoint for lg screens */
  .responsive-div {
    width: 100%; /* For small screens (below lg) */
  }
}
.faq {
  bottom: 4%;
  left: 3%;
}
.faqButton {
  background-image: linear-gradient(147deg, #a8c627 0%, #25be6c 74%);
}
.faqButton:hover {
  animation: jello-vertical 1s ;
}

@keyframes jello-vertical {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 1.25, 1);
  }
  
  75% {
    transform: scale3d(1.25, 1.25, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

.faq-card {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease-in-out, transform 0.5s ease-in-out;
}

.faq-card.show-animation {
  /* Apply your animation styles here */
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease-in-out, transform 0.5s ease-in-out;
}

.errorBlueHollowCircle {
  border-radius: 100%;
  width: 50vh;
  height: 50vh;
  left: 80.04%;
  /* right: -12.71%; */
  top: 6.31%;
  /* bottom: -21.88%; */
  border: 50px solid #7d91ea;
  filter: drop-shadow(0px -75px 138px rgba(0, 0, 0, 0.25));
  transform: rotate(100deg);
  overflow: hidden;
}

.cardShadow {
  filter: drop-shadow(0px 0px 0px #b3d9ff);
  width: 60vw;
  border: #b3d9ff;
  border-radius: 20px;
  height: 88vh;
  z-index: 3;
  backdrop-filter: blur(10px);
}

.purpleEllipse {
  /* box-sizing: border-box; */

  /* position: absolute; */
  left: -4.71%;
  right: 71.04%;
  top: 70.04%;
  bottom: -16.6%;

  border: 50px solid #3107cbb3;
  filter: drop-shadow(50px 50px 138px rgba(0, 0, 0, 0.5));
  width: 55vh;
  height: 55vh;
  border-radius: 50%;
  transform: rotate(220deg);
  overflow: hidden;
  -webkit-filter: blur(80px);
  filter: blur(80px);
}

.blueSmallCircle {
  left: -3.19%;
  right: 91.18%;
  top: 82.9%;
  bottom: -21%;
  width: 143px;
  height: 143px;
  background: #6258ff;
  box-shadow: -26px 14px 138px rgba(0, 0, 0, 0.25);
  transform: rotate(-90deg);
  border-radius: 100%;
}

.blueSolidCircle {
  left: 89.76%;
  right: -10.18%;
  top: -2.95%;
  bottom: 45.34%;
  width: 190px;
  height: 190px;
  background: #1d0779;
  box-shadow: -26px 14px 138px rgba(0, 0, 0, 0.25);
  transform: rotate(-90deg);
  border-radius: 100%;
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden !important;
}

.blueHollowCircle {
  box-sizing: border-box;
  position: absolute;
  left: 78.37%;
  right: -5.59%;
  top: 2.94%;
  bottom: 18.78%;
  width: 300px;
  height: 300px;
  border: 44px solid #7d91ea;
  filter: drop-shadow(26px 14px 138px rgba(0, 0, 0, 0.25));
  transform: rotate(-90deg);
  border-radius: 100%;
  overflow: hidden;
  overflow-x: hidden;
}

.rcorners1 {
  margin-top: 20px;
  border-radius: 25px;
  background: #fdda36;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: inline-block;
  font-size: calc(0.6vw + 0.6vh);
  font-weight: bold;
  color: #130061;
  text-decoration: none;
  border: none;
}

.rcorners1:hover {
  text-decoration: none;
  color: #130061;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .rcorners1 {
    font-size: 100%;
  }
}

@media (min-width: 480px) and (max-width: 560px) {
  .rcorners1 {
    font-size: 80%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .rcorners1 {
    font-size: 70%;
  }
}

/* @import url("https://fonts.googleapis.com/css?family=Roboto"); */

body {
  /* just to make it feel a little more at home */
  font-family: var(--font-family-adv) !important;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.floating-label {
  position: relative;
}

/* 
.floating-label input {
	border: 1px solid gray;
	margin-bottom: 1px;
} */
/* 
.floating-label input:hover {
	border-bottom: 2px solid black;
	margin-bottom: 0;
} */

/* .floating-label input:focus,
.floating-label input:active {
	outline: none;
	border: 1px solid #3107cb;
	margin-bottom: 0;
} */

.floating-label input+label {
  background-color: #f5f6fa;
  position: absolute;
  pointer-events: none;
  bottom: 0.25em;
  left: 0.4em;
  color: gray;
  font-size: 1em;
  transition: margin 0.2s ease, color 0.2s ease, font-size 0.2s ease;
}

.floating-label input:focus+label,
.floating-label input:active+label,
.floating-label input:valid+label {
  pointer-events: auto;
  margin-bottom: 2.8em;
  font-size: 0.8em;
}

.floating-label input:focus+label,
.floating-label input:active+label {
  padding-left: 5px;
  padding-right: 5px;
  color: #3107cb;
}

.floating-label input.empty:not(:focus)+label,
.floating-label input.empty:not(:active)+label {
  pointer-events: none;
  margin-bottom: 0;
  font-size: 1em;
}

.floating-label input:not([value=""]):not(:focus):invalid+label,
.floating-label input:not([value=""]):not(:active):invalid+label {
  pointer-events: auto;
  margin-bottom: 2.8em;
  font-size: 0.8em;
  color: red;
}

.floating-label input:not([value=""]):not(:focus):invalid,
.floating-label input:not([value=""]):not(:active):invalid {
  border: 2px solid red;
}

.emailInvite {
  border: 5px solid #3107cb;
}

.container {
  margin-top: 5rem;
  /* box-shadow: 10px 10px 8px 10px #888888; */
}

@media (max-width: 760px) {
  .container {
    margin-top: 0rem;
  }
}

.ot {
  overflow-y: hidden;
}

.bg-gradient {
  height: 100vh;
  width: 100vw;
  background: -webkit-linear-gradient(117deg,
      #3107cb,
      #3107cb 50%,
      white 50%,
      white);

  /* background-color: #edf5fd; */
  /* background: -webkit-linear-gradient(to right, #f64f59, #c471ed, #12c2e9);  /* Chrome 10-25, Safari 5.1-6 */
  /* background: linear-gradient(to right, #f64f59, #c471ed, #12c2e9);  */
}

.icon-style {
  margin-top: 3%;
  margin-bottom: 5%;
}

.regImage {
  background-image: url("../left.png");
  background-repeat: no-repeat;
  margin-left: -17px;
}


.resetImage {
  background-image: url("../registration.svg");
  width: 80vh;
  border-bottom-left-radius: 0.7em;
  overflow: hidden;
  /* background-size: cover; */
  background-repeat: no-repeat;
  height: 66vh;
  object-fit: cover;
}

@media (min-width: 1024px) {
  .resetImage {
    height: 66vh;
    background-size: cover;
  }
}

@media (min-width: 1280px) {
  .resetImage {
    height: 49.5vh;
    background-size: cover;
  }
}

.newAdminImage {
  background-image: url("../registration.svg");
  width: 120vh;
  /* border-bottom-left-radius: 0.7em; */
  overflow: hidden;
  /* background-size: cover; */
  background-repeat: no-repeat;
  height: 81vh;
}

@media (min-width: 768px) and (max-width: 820px) {
  .resetImage {
    background-image: url("../registration.svg");
    width: 120vh;
    border-bottom-left-radius: 0.7em;
    overflow: hidden;
    /* background-size: cover; */
    background-repeat: no-repeat;
    height: 59.5vh;
    object-fit: cover;
  }
}

@media (min-width: 912px) and (max-width: 912px) {
  .resetImage {
    background-image: url("../registration.svg");
    width: 120vh;
    border-bottom-left-radius: 0.7em;
    overflow: hidden;
    /* background-size: cover; */
    background-repeat: no-repeat;
    height: 50vh;
    object-fit: cover;
  }
}

.loginImage {
  background-image: url("../login.svg");
  width: 120vh;
  /* border-bottom-left-radius: 0.7em; */
  overflow: hidden;
  /* background-size: cover; */
  background-repeat: no-repeat;
}

.loginImage1 {
  background-image: url("../login1.svg");
  width: 124vh;
  /* border-radius: 0.7em; */
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
  margin-left: -29px;
  /* height: 70vh; */
}

.errorImage {
  background-image: url("../ErrorPage.svg");
  width: 120vh;
  /* border-radius: 0.7em; */
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
  /* margin-left: -29px; */
  height: 80vh;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .loginImage1 {
    background-size: inherit;
    background-image: url("../login1.svg");
    width: 124vh;
    /* border-radius: 0.7em; */
    overflow: hidden;
    background-repeat: no-repeat;
    object-fit: contain;
    margin-left: -45px;
    /* height: 50vh; */
  }
}

@media (min-width: 1204px) and (max-width: 1279px) {
  .loginImage1 {
    background-size: inherit;
    background-image: url("../login1.svg");
    width: 124vh;
    /* border-radius: 0.7em; */
    overflow: hidden;
    background-repeat: no-repeat;
    object-fit: contain;
    margin-left: -45px;
    /* height: 50vh; */
  }
}

@media (min-width: 1280px) {
  .loginImage1 {
    width: 124vh;
    height: 67vh;
  }
}

.loginImage2 {
  background-image: url("../login2.svg");
  width: 120vh;
  border-bottom-left-radius: 0.7em;
  overflow: hidden;
  /* background-size: cover; */
  background-repeat: no-repeat;
}

@media (max-width: 767px) {
  .mid {
    margin-top: 180px;
  }
}